@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
.footer {
    background: $color-gradient-1;
}

.footerInner {
    align-items: center;
    overflow: hidden;
    max-width: map-get($breakpoints, 'extra-large');
    margin: auto;

    @include tablet() {
        display: grid;
        grid-template-areas:
            'logo socialLinks'
            'termsContainer ssgAndWarning'
            'copyright ssgAndWarning';
        grid-template-rows: auto auto 1fr;
        grid-template-columns: 3fr 1fr;
        gap: 0 0;
    }
}

.logo {
    display: block;
    grid-area: logo;
    margin: rem(20) auto rem(16) auto;

    @include tablet() {
        //todo: this should use pw tokens
        margin: rem(34) rem(16) rem(16) rem(16);
    }
}

.termsContainer {
    grid-area: termsContainer;

    hr {
        max-width: 37%;
        height: rem(1);
        margin: 0 auto;
        border: 0;
        border-style: solid;
        border-top: rem(1) solid $color-neutral-700;

        @include tablet() {
            display: none;
        }
    }
}

.terms {
    max-width: 75%;
    margin: rem(15) auto;
    color: $color-neutral-white;
    font-size: rem(12);
    line-height: rem(24);
    text-align: center;

    @include tablet() {
        margin: 0 rem(16) rem(16) rem(16);
        text-align: left;
    }
}

.links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 75%;
    margin: rem(16) auto rem(22) auto;
    padding-left: 0;

    @include tablet() {
        justify-content: flex-start;
        margin: rem(16);
    }
}

.linksListItem {
    margin-left: rem(5);
    color: $color-primary-300;
    list-style: none;

    a {
        color: inherit;
        font-size: rem(12);
        line-height: rem(24);

        &:hover {
            text-decoration: underline;
        }

        &::after {
            content: '\2022';
            display: inline-block;
            margin-left: rem(5);
            color: $color-neutral-white;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        a {
            &::after {
                display: none;
            }
        }
    }
}

.socialLinks {
    display: flex;
    grid-area: socialLinks;
    justify-content: center;
    margin: rem(27) 0 rem(24) 0;

    @include tablet() {
        justify-content: flex-end;
        margin: rem(34) 0 rem(24) 0;
    }

    a {
        text-decoration: none;
    }
}

.socialIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
    margin: 0 rem(8);
    border-radius: 50%;
    background-color: $color-neutral-700;
    opacity: 0.8;

    &:first-child {
        margin-left: rem(16);
    }

    &:last-child {
        margin-right: rem(16);
    }

    &:hover {
        opacity: 1;
    }

    span {
        &::before {
            color: $color-primary-300;
        }
    }
}

.ssgAndWarning {
    display: flex;
    grid-area: ssgAndWarning;
    align-items: center;
    justify-content: center;
    margin: rem(34) 0;

    @include tablet() {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        height: 100%;
        margin: rem(23) 0;
    }
}

.ssgLogo {
    margin: 0 rem(16);

    @include tablet() {
        order: -1;
        margin: 0 rem(16) rem(28) rem(16);
    }
}

.ageWarning {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(32);
    height: rem(32);
    margin: 0 rem(16) 0 0;
    border: rem(4) solid $color-secondary-400;
    border-radius: 50%;
    background-color: $color-neutral-white;
    font-weight: bold;
    font-size: rem(12);
    letter-spacing: rem(-1);
}

.copyright {
    grid-area: copyright;
    margin: rem(16);
    color: $color-neutral-400;
    font-weight: 600;
    font-size: rem(12);
    line-height: rem(24);
    text-align: center;

    @include tablet() {
        margin: rem(0) rem(16) rem(50) rem(16);
        text-align: left;
    }
}
