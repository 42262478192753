body.fonts-loaded {
  font-family: 'Open Sans';
}

@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400;1,600&display=swap');

@font-face {
  font-weight: normal;
  font-style: normal;
  font-family: 'pickswise-iconfont';
  src: url('~@ssgat/react-components/build/assets/fonts/pickswise-iconfont.eot?vbtbac');
  src: url('~@ssgat/react-components/build/assets/fonts/pickswise-iconfont.eot?vbtbac#iefix')
  format('embedded-opentype'),
  url('~@ssgat/react-components/build/assets/fonts/pickswise-iconfont.ttf?vbtbac')
  format('truetype'),
  url('~@ssgat/react-components/build/assets/fonts/pickswise-iconfont.woff?vbtbac')
  format('woff'),
  url('~@ssgat/react-components/build/assets/fonts/pickswise-iconfont.svg?vbtbac#pickswise-iconfont')
  format('svg');
  font-display: block;
}
