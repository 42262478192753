@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
@import "variables";
@import "3rdParty/index";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: $color-neutral-100;
}

// loader styles
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1031;
  width: 100%;
  height: rem(2);
  background-color: $color-primary-400;
}

#nprogress .peg {
  position: absolute;
  right: 0;
  display: block;
  width: rem(100);
  height: 100%;
  box-shadow: 0 0 rem(10) $color-primary-400, 0 0 rem(5) $color-primary-400;
  opacity: 1;
  transform: rotate(3deg) translate(0, rem(-4));
}
