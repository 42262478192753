@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
.twitter {
    display: none;

    @include desktop {
        display: block;
    }
}

section {
    &.hideGutters {
        padding-right: 0;
        padding-left: 0;
    }
}
