@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
//important! px used so that the min-width clause works in the media query.
// if this breaks in the future it'll be because the $breakpoints map has been converted to rem
$container_width: calc(
    #{map-get($breakpoints, 'extra-large')} + ((120px + 16px) * 2)
);

.container {
    margin: $spacing-stacking-s auto 0 auto;
    max-width: $container_width;
    position: relative;
}

.left,
.right {
    display: none;
    position: absolute;
    top: 100%;
    @media screen and (min-width: $container_width) {
        display: block;
    }
}
.right {
    right: 0;
}
