@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
.adWrap {
    text-align: center;
    overflow: hidden;
    height: 0;
    transition: height linear 500ms;
    width: var(--width);
    margin: auto;
}
.loaded {
    height: var(--height);
}
