@import "./node_modules/@ssgat/pw-design-tokens/static/styles.scss";@import "./node_modules/@ssgat/react-components/build/assets/scss/index.scss";
.content {
    display: contents;

    @include desktop {
        display: block;
    }
}

.main {
    composes: content;
    width: 100%;

    @include desktop {
        grid-area: main;
    }
}

.featured {
    composes: content;

    @include desktop {
        grid-area: featured;
    }
}

.sidebar {
    composes: content;

    @include desktop {
        grid-area: sidebar;
    }
}

.section {
    order: var(--mobile-order);
    padding: $spacing-stacking-m 0 $spacing-stacking-l;

    @include mobile {
        padding: $spacing-stacking-s 0 $spacing-stacking-m;
    }
}

.toMobileEdge {
    overflow: hidden;

    @include toMobileEdge(true);

    @include desktop {
        overflow: initial;
    }
}

.divider {
    &:not(:last-child) {
        @include borderDivider();
    }
}


.grid {
    display: block;
    grid-auto-rows: min-content; // collapse all those implicit rows, otherwise Sidebar will put in extra rows can also use  grid-template-rows: min-content 1fr;
    max-width: map-get($breakpoints, 'extra-large');
    min-height: 80vh; // give ht to pages with no or little content: 404 page, app download, empty tips pages ... gives a standard ht , rather than collapsed
    margin: 16px auto 32px;
    padding: 0 $spacing-xs;

    @include min('small') {
        padding: 0 $spacing-xs;
    }

    @include tablet {
        padding: 0 $spacing-m;
    }

    @include desktop {
        display: grid;
        grid-template-areas:
            'featured'
            'main'
            'sidebar';
    }
}

.filterGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    align-items: start;

    @include desktop {
        grid-template-columns: rem(310) minmax(0, 1fr);
    }
}

.withSidebar {
    @include desktop {
        grid-template-areas:
            'featured featured'
            'main sidebar';
        grid-template-rows: auto 1fr;
        grid-template-columns:
            minmax(rem(634), rem(885))
            minmax(rem(309), rem(351));
        grid-column-gap: $spacing-stacking-l;
    }
}
